import Vue from 'vue'
import axios from 'axios'

const initState = {
  paymentMethods: null,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, paymentMethods) {
      state.paymentMethods = paymentMethods
    },

    createSuccess(state, paymentMethod) {
      this.state.auth.user.default_payment_method = paymentMethod.id

      if (state.paymentMethods) {
        state.paymentMethods = [...state.paymentMethods, paymentMethod]
      }
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    setDefaultSuccess(state, paymentMethodId) {
      this.state.auth.user.default_payment_method = paymentMethodId
    },

    removeSuccess(state, paymentMethodId) {
      let index = state.paymentMethods.findIndex(
        (method) => method.id === paymentMethodId
      )
      state.paymentMethods.splice(index, 1)
    },
  },

  actions: {
    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payment-methods')
          .then((res) => {
            commit('getAllSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        axios
          .post('/payment-methods', {
            payment_method: paymentMethodId,
          })
          .then((res) => {
            commit('createSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getSecret({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payment-methods/secret')
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    setDefault({ commit }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/payment-methods/${paymentMethodId}/default`)
          .then(() => {
            commit('setDefaultSuccess', paymentMethodId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/payment-methods/${paymentMethodId}`)
          .then(() => {
            commit('removeSuccess', paymentMethodId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
  },

  getters: {
    all(state) {
      return state.paymentMethods
    },
  },
}
