import store from '@store/index'

function BusinessGuard(to, from, next) {
  let user = store.getters['auth/user']

  if (!user) {
    next({ name: 'login' })
  } else if (user.role !== 'Business') {
    next('/')
  } else if (!user.business.phone_number) {
    if (to.name !== 'business.setup-phonenumber') {
      next({ name: 'business.setup-phonenumber' })
    } else {
      next()
    }
  } else {
    next()
  }
}

export default BusinessGuard
