import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import BusinessGuard from './guards/business-guard'

export default [
  {
    path: '/business/',
    component: DashboardLayout,
    beforeEnter: BusinessGuard,
    redirect: { name: 'business.dashboard' },
    children: [
      {
        name: 'business.dashboard',
        path: 'dashboard',
        component: lazyLoading('Business/Dashboard'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings',
        path: 'settings',
        component: lazyLoading('Business/Settings'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.setup-phonenumber',
        path: 'setups/phone-number',
        component: lazyLoading('Business/Setups/PhoneNumber'),
        beforeEnter: BusinessGuard,
      },
    ],
  },
]
