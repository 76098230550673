import store from '@store/index'

function AgencyGuard(to, from, next) {
  let user = store.getters['auth/user']
  if (!user) {
    next({ name: 'login' })
  } else if (user.role !== 'Agency') {
    next('/')
  } else if (!user.subscribed && !user.agency.free_fee) {
    if (to.name !== 'agency.subscription') {
      next({ name: 'agency.subscription' })
    } else {
      next()
    }
  } else if(user.email_validation_required && user.email_verified_at == null){
    if (to.name !== 'agency.verify') {
      next({ name: 'agency.verify' })
    } else {
      next()
    }
  } else {
    next()
  }
}

export default AgencyGuard
