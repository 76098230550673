import Vue from 'vue'
import axios from 'axios'

const initState = {
  landingpage: null
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {

  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
    find({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/landingpage/${pageId}`)
            .then((res) => {
              this.landingpage = res.data.data;
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
  },
}
