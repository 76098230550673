<template>
  <ValidationProvider v-slot="{ failed, errors }" rules="required" :name="name">
    <div class="form-group">
      <label v-if="label" class="form-control-label">{{ label }}</label>
      <multiselect
        v-model="iValue"
        :options="sortedOption"
        label="label"
        :class="{ 'is-invalid': failed }"
      ></multiselect>
      <b-form-invalid-feedback v-if="errors.length">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </div>
  </ValidationProvider>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Vue from 'vue'

export default {
  components: {
    Multiselect,
  },

  props: {
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: 'Timezone',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: [Array],
      default: () => [],
    },
  },

  computed: {
    sortedOption() {
      let sortedOption = Vue.util.extend([], this.options)
      const defaultIndex = sortedOption.findIndex(item => item.value === this.value)

      if (defaultIndex !== -1)
      {
        const defaultTimezone = sortedOption[defaultIndex]
        sortedOption.splice(defaultIndex, 1)
        return [
          defaultTimezone,
          ...sortedOption
        ]
      }
      return sortedOption
    },

    iValue: {
      get() {
        let index = this.options.findIndex((o) => o.value === this.value)

        return index !== -1 ? this.options[index] : null
      },

      set(newValue) {
        this.$emit('input', newValue ? newValue.value : null)
      },
    },
  },
}
</script>
