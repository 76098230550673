import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  index: {
    agencies: null,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
    total: 0,
  },
  archived: {
    agencies: null,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
    total: 0,
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { agencies, page, queries, total }) {
      state[page] = {
        agencies: agencies,
        queries: queries,
        total: total,
      }
    },

    createSuccess(state, { agency }) {
      state.index.agencies = null
    },

    updateSuccess(state, { id, agency }) {
      state.index.agencies = null
    },

    restoreAgencySuccess(state, agency) {
      state.index.agencies = null
    },

    archiveSuccess(state) {
      state.archived.agencies = null
    },

    editSettingsSuccess(state, user) {
      this.state.auth.user = user
    },

    currentPage(state, page) {
      state.currentPage = page
    },

    uploadWhitelableFileSuccess(state, { type, path }) {
      this.state.auth.user.agency.whitelabel[type] = path
    },

    deleteWhitelableFileSuccess(state, type) {
      this.state.auth.user.agency.whitelabel[type] = null
    },

    toggleIsNewSuccess(state) {
      this.state.auth.user.agency.is_new = false
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAll({ commit, state }, { queries, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/agencies', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              agencies: res.data.data,
              page: page,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAnalytics({ commit }, { query, agencyId }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/analytics/${agencyId}`, { params: {query:query} })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    find({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/agencies/${agencyId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, agency) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/agencies`, agency)
          .then((res) => {
            commit('createSuccess', { agency: res.data.data })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, agency) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/agencies/${agency.id}`, agency)
          .then((res) => {
            commit('updateSuccess', { id: agency.id, agency: res.data.data })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    toggleIsNew({ commit }, agency) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/agencies/${agency.id}/updatePartial`, {
            is_new: false,
          })
          .then((res) => {
            commit('toggleIsNewSuccess')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    archive({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/agencies/${agencyId}`)
          .then((res) => {
            commit('archiveSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/agencies/${agencyId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    restore({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/agencies/${agencyId}/restore`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

    editSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/agencies/edit-settings`, params)
          .then((res) => {
            commit('editSettingsSuccess', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getTraining({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/agencies/get-training`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    uploadWhitelableFile({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/agencies/whitelable-file`, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            commit('uploadWhitelableFileSuccess', {
              type: params.get('type'),
              path: res.data.data.path,
            })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteWhitelableFile({ commit }, type) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/agencies/whitelable-file/${type}`)
          .then((res) => {
            commit('deleteWhitelableFileSuccess', type)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    editLandingPage({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/agencies/edit-landing`, params,{
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => {
              commit('editSettingsSuccess', res.data.data)
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
  },

  getters: {
    total(state) {
      return state[state.currentPage].total
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    all(state) {
      return state[state.currentPage].agencies
    },

    allActive(state) {
      return state.index.agencies
    },
  },
}
