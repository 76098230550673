import lazyLoading from './lazyLoading'

export default [
  {
    path: '/',
    redirect: {name: 'login'},
    name: 'home',
  },
  {
    path: '/order',
    component: lazyLoading('Auth/Order'),
    name: 'order',
  },
  {
    name: 'email.validate',
    path: '/validate/:validation_key',
    component: lazyLoading('Auth/ValidateEmail'),
  },
]
