<template>
  <div id="app">
    <router-view></router-view>
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary" style="width:80px;height:80px;" />
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    config() {
      return this.$store.getters['app/config']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    loading() {
      return this.$store.getters['app/loading']
    },
  },
  metaInfo () {
    return {
      title: 'Review Link Generator'
    }
  },
  mounted() {
    this.$store.dispatch('app/getConfig', location.hostname)
  },
}
</script>

<style lang="scss">
@import '@design';

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
