import 'vue-toast-notification/dist/theme-default.css';

import Vue from 'vue'
import App from './app'
import store from './store/index'
import '@components/_globals'
import router from './router/index'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import vco from 'v-click-outside'
import VueFeather from 'vue-feather'
import flatPickr from 'vue-flatpickr-component'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import './configs/http'
import TextInput from '@components/Common/TextInput'
import SelectInput from '@components/Common/SelectInput'
import PhoneInput from '@components/Common/PhoneInput'
import BirthdayInput from '@components/Common/BirthdayInput'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueToast from 'vue-toast-notification';
import VueTelInput from 'vue-tel-input'
import VueMeta from 'vue-meta'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

// Filters
import './filters/currency'
import './filters/datetime'
import './filters/strlimit'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('customurl', {
  validate: (value) => {
    return (
      !value.trim().startsWith('www.') &&
      !value.trim().startsWith('http') &&
      value.match(
        /^[a-zA-Z0-9|-]{1,61}[a-zA-Z0-9|-](?:\.[a-zA-Z0-9|-]{2,})+$/
      )
    )
  },
  message: 'Domain format is invalid.',
})

extend('subdomain', {
  validate: (value) => {
    return (
      value &&
      value.length < 63 &&
      value.match(/^[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/)
    )
  },
  message: 'Subdomain format is invalid.',
})
extend('phone_number', {
  validate: (value) => {
    return value && value.match(/^\(\d{3}\)\s\d{3}-\d{4}$/)
  },
  message: 'Phone number is not valid'
});
extend('include', {
  params: ['word'],
  validate: (value, { word }) => {
    return value.trim().indexOf(word) !== -1
  },
  message: `The field requires {word}`,
})

Vue.use(VueFeather)
Vue.use(flatPickr)
Vue.use(VueMoment, { moment })
Vue.use(vco)
Vue.use(VueToast, {
  position: 'bottom'
});
Vue.use(VueMeta)
// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueTelInput, {
  validCharactersOnly: true,
  mode: 'international',
  name: 'phone_number',
  dropdownOptions: {
    disabledDialCode: false,
  },
  inputOptions: {
    showDialCode: false,
  },
})

// Components
Vue.component('BirthdayInput', BirthdayInput)
Vue.component('PhoneInput', PhoneInput)
Vue.component('TextInput', TextInput)
Vue.component('SelectInput', SelectInput)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Sentry.init({
  environment: process.env.NODE_ENV || 'development',
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
})

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
