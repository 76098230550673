<template>
  <div id="wrapper" :class="{ 'has-notification': hasNotification }">
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="top-notification-bar"
      :class="{
        'danger text-white': notificationData['status'] === 'danger',
        'warning': notificationData['status'] === 'warning',
      }"
      v-html="notificationData['msg']"
    ></div>
    <!--eslint-enable-->
    <Topbar v-show="!blockedScreen" :is-menu-opened="showMobileMenu" />
    <SideBar v-show="!blockedScreen" v-if="user" />
    <div class="content-page" :class="{ 'blocked-screen': blockedScreen }">
      <div class="content" style="margin-top:10px;">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topbar from './Partials/Topbar'
import SideBar from './Partials/SideBar'

export default {
  components: { Topbar, SideBar },
  metaInfo () {
    return {
      link: [
        { hid: 'favorite-icon', rel: 'icon', href: 'https://reviewour.biz/favicon.ico' , type: 'image/x-icon' }
      ]
    }
  },
  data() {
    return {
      showMobileMenu: false,
    }
  },
  computed: {
    blockedScreen() {
      return this.$store.getters['auth/blockedScreen']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    hasNotification() {
      return this.user && this.user.role === 'Business' && (
        this.user.business.no_messages && this.user.business.usage_messages >= this.user.business.no_messages * .9 ||
        this.user.business.no_contacts && this.user.business.usage_contacts >= this.user.business.no_contacts * .9
      )
    },

    notificationData() {
      let data = {
        'status': undefined,
        'msg': ''
      }
      if (this.user && this.user.role === 'Business')
      {
        const agencyEmail = this.user.business.agency.email;
        if (this.user.business.no_messages && this.user.business.usage_messages >= this.user.business.no_messages * .9)
        {
          if (this.user.business.usage_messages >= this.user.business.no_messages )
          {
            data = {
              'status': 'danger',
              'msg': `You have reached your monthly text messaging limit. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
            }
          } else {
            data = {
              'status': 'warning',
              'msg': `You are at 90% of your monthly text message limits. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
            }
          }
        }
        else if (this.user.business.no_contacts && this.user.business.usage_contacts >= this.user.business.no_contacts * .9)
        {
          if (this.user.business.usage_contacts >= this.user.business.no_contacts )
          {
            data = {
              'status': 'danger',
              'msg': `You have reached the limit of your total contacts. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
            }
          } else {
            data = {
              'status': 'warning',
              'msg': `You are at 90% of your total contacts allowed on your plan. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
            }
          }
        }
      }
      return data
    },
  },

  methods: {
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
  },
}
</script>
