<template>
  <div class="form-group">
    <label class="form-control-label">
      Birthday
    </label>
    <div class="row">
      <div class="col-6">
        <select v-model="month" name="month" class="form-control" >
          <option value="">Month</option>
          <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
        </select>
      </div>
      <div class="col-6">
        <select v-model="day" name="day" class="form-control" >
          <option value="">Day</option>
          <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: {
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      tempMonth: "",
      tempDay: "",
    }
  },

  computed: {
    month: {
      get() {
        if (!this.value) return this.tempMonth;

        return moment(this.value).format('MM')
      },
      set(newValue) {
        this.tempMonth = newValue
        if (newValue !== "" && this.day !== "") {
          this.$emit('input', `2020-${newValue}-${this.day}`)
        }
      },
    },
    day: {
      get() {
        if (!this.value) return this.tempDay;

        return moment(this.value).format('DD')
      },
      set(newValue) {
        this.tempDay = newValue
        if (newValue !== "" && this.month !== "") {
          this.$emit('input', `2020-${this.month}-${newValue}`)
        }
      },
    
    },
  },
}
</script>
